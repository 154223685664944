import type { Notification } from '~~/types/Notification'

export const notificationStore = defineStore('notificationStore', () => {
  const list = ref<Notification[]>([])

  const show = (item: Notification) => {
    list.value.push(item)
    return true
  }
  const close = (id: Notification['id']) => {
    list.value = list.value.filter((item: Notification) => item.id !== id)
    return true
  }

  return { list, show, close }
})
